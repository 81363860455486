import React, { useReducer } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";

import clienteAxios from "../../config/axios";

import {
  OBTENER_USUARIO,
  LOGIN_EXISTOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    mensaje: null,
    cargando: true
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Las funciones

  // Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");
    try {
      const respuesta = await clienteAxios
        .get(`/api/user/v1/verifyToken/${token}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
      dispatch({
        type: OBTENER_USUARIO,
        payload: respuesta.data.data.user,
      });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  // Cuando el usuario inicia sesión
  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post("/oauth/v2/token", {
        username: datos.username,
        password: datos.password,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_SECRET,
        grant_type: "password",
      });
      dispatch({
        type: LOGIN_EXISTOSO,
        payload: respuesta.data
      })
      // Obtenere el usuario
      usuarioAutenticado();
    } catch (error) {
      if (error.response.status === 400) {
        const alerta = {
          msg: error.response.data.error_description,
          categoria: "alerta-error"
        }
        dispatch({
          type: LOGIN_ERROR,
          payload: alerta,
        });
      }
    }
  };

    // Cerrar sesión 
    const cerrarSesion = () => {
      dispatch({
        type: CERRAR_SESION
      });
    }
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        autenticado: state.autenticado,
        usuario: state.usuario,
        mensaje: state.mensaje,
        cargando: state.cargando,
        iniciarSesion,
        cerrarSesion,
        usuarioAutenticado
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
