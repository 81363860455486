import React from "react";

const Footer = () => {
  return (
    <footer>
      <p>
        Copyright © <span>Repartos y Servicios S.L. </span>
        {new Date().getFullYear()}
      </p>
    </footer>
  );
};

export default Footer;
