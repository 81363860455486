import React from "react";

import NewSearch from "../search/NewSearch";

const Sidebar = ({setInfo}) => {

  return (
    <aside>
      <h1>
        Buscador <span>Pedidos</span>
      </h1>

      <NewSearch setInfo={setInfo} />
    </aside>
  );
};

export default Sidebar;
