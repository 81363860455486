import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import Search from "./components/search/Search";
import Result from "./components/result/Result";
import Footer from "./components/layout/Footer";

import AlertaState from "./context/alertas/alertaState";
import AuthState from "./context/autenticacion/authState";
import RutaPrivada from "./components/rutas/RutaPrivada";

function App() {
  return (
    <AuthState>
    <AlertaState>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <RutaPrivada exact path="/search" component={Search} />
          <RutaPrivada exact path="/result" component={Result} />
        </Switch>
      </Router>
      </AlertaState>
      <Footer />
    </AuthState>
    
  );
}

export default App;
