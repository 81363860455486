import React, {useContext} from 'react';
import AuthContext from '../../context/autenticacion/authContext';

const Header = () => {
    const authContext = useContext(AuthContext);
    const {cerrarSesion} = authContext;
    return ( 
        <header className="app-header">
            <p className="nombre-usuario"><span>Reser </span>S.L.</p>

            <nav className="nav-principal">
            <button 
                className="btn btn-blank cerrar-sesion" 
                onClick={() => cerrarSesion()}
                >Cerrar Sesión</button>
                
            </nav>
        </header>
     );
}
 
export default Header;