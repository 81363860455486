import React, { Fragment } from "react";

const getStatusTranslation = (status) => {
  switch (status) {
    case "created":
      status = "Creado";
      break;
    case "incident":
      status = "Incidencia";
      break;
    case "absent":
      status = "Ausente";
      break;
    case "delivered":
      status = "Entregado";
      break;
    case "on_route":
      status = "En ruta";
      break;
    case "received":
      status = "Clasificado";
      break;
    case "sent_back":
      status = "Devuelto";
      break;
    case "canceled":
      status = "Cancelado";
      break;
    case "not_received":
      status = "No recibido";
      break;
    default:
      status = "Error";
      break;
  }
  return status;
};

const Result = ({ pedido, error }) => {
  if (error) {
    return <div className="alert alert-danger text-center p-2">{error}</div>;
  } else if (pedido === null) {
    return null;
  } else {
    return (
      <Fragment>
        <h1>Datos</h1>
        <div className="contenedor-tabla">
          <table className="datagrid tabla-info" size="sm">
            <thead className="datagrid">
              <tr className="datagrid">
                <th className="datagrid">Cliente</th>
                <th className="datagrid">Población</th>
                <th className="datagrid">Nº de documento</th>
                <th className="datagrid">Estado</th>
              </tr>
            </thead>
            <tbody className="datagrid">
              <tr className="datagrid">
                <td className="datagrid">{pedido.data.data.shop.name}</td>
                <td className="datagrid">{pedido.data.data.shop.town.name}</td>
                <td className="datagrid">
                  {pedido.data.data.shipping.delivery_code}
                </td>
                <td className="datagrid">{pedido.data.data.statusES}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h1>Historial</h1>
        <div className="contenedor-tabla-historial">
          <table className="datagrid tabla-historial" size="sm">
            <thead className="datagrid">
              <tr className="datagrid">
                <th className="datagrid" colspan="2">
                  Fecha
                </th>
                <th className="datagrid">Estado</th>
              </tr>
            </thead>
            <tbody className="datagrid">
              {pedido.data.data.shipping.shipping_history.map(
                (shipping_hist, index) => (
                  <tr className="datagrid" data-index={index}>
                    <td className="datagrid">
                      {new Date(shipping_hist.date).toLocaleDateString()}
                    </td>
                    <td className="datagrid">
                      {new Date(shipping_hist.date).toLocaleTimeString()}
                    </td>
                    <td className="datagrid">
                      {getStatusTranslation(shipping_hist.status)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {pedido.data.data.statusES === "Entregado" ? (
          <div>
            {pedido.data.data.route === "0" ? (
              <div>
                <h1>Fotosello</h1>
                <div className="contenedor-tabla-historial">
                  <a
                    href={pedido.data.data.albaran_box}
                    target="_blank"
                    rel="noreferrer"
                    className="box fotosello"
                  >
                    Ver fotosello
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <h1>Firma</h1>
                <div className="contenedor-tabla-historial">
                  <img
                    className="firma box"
                    src={
                      "https://www.resersl.com/images/uploads_dir/" +
                      pedido.data.data.signature
                    }
                    alt="firma"
                  />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </Fragment>
    );
  }
};

export default Result;
