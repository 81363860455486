import React, { Fragment, useState } from "react";

const NewSearch = ({setInfo}) => {
  // State para Busqueda
  const [errorDoc, setErrorDoc] = useState(false);
  const [documento, setDocumento] = useState({
    numero: "",
  });

  // Extraer numero de documento
  const { numero } = documento;

  // Lee los contenidos del input
  const onChangeDoc = (e) => {
    setDocumento({
      ...documento,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando se busca un pedido
  const onSubmitSearch = (e) => {
    e.preventDefault();

    if (!numero.length > 0) {
      setErrorDoc(true);
      return;
    }
    setErrorDoc(false);
    // Todo bien, pasar al componente principal
    setInfo(documento);
  };

  return (
    <Fragment>
      <form className="formulario-nuevo-proyecto" onSubmit={onSubmitSearch}>
        <input
          type="text"
          className="input-text"
          placeholder="Número de documento"
          name="numero"
          value={numero}
          onChange={onChangeDoc}
        />
        <br/>
        { errorDoc ? <p className="alert alert-danger text-center p-2">Debe introducir el número de documento</p> : null}
        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Buscar"
        />
      </form>
    </Fragment>
  );
};

export default NewSearch;
