import {
  OBTENER_USUARIO,
  LOGIN_EXISTOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
} from "../../types";

const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER_USUARIO:
      return {
        ...state,
        autenticado: true,
        usuario: action.payload,
        cargando: false
      };
    case CERRAR_SESION:
    case LOGIN_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        mensaje: action.payload,
        cargando: false
      };
    case LOGIN_EXISTOSO:
      localStorage.setItem("token", action.payload.access_token);
      return {
        ...state,
        token: action.payload.access_token,
        autenticado: true,
        mensaje: null,
        cargando: false
      };
      default:
            return state;
  }
};
export default reducer;