import React, { useState, useEffect } from "react";
import clienteAxios from '../../config/axios';

import SideBar from "../layout/Sidebar";
import Header from "../layout/Header";
import Result from "../result/Result";

const Search = () => {
  const [info, setInfo] = useState({});
  const [pedido, setPedido] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (Object.keys(info).length === 0) return;

    const handleSearch = async () => {
      const { numero } = info;
      const token = localStorage.getItem("token");
      setError(null);
      setPedido(null);

      await clienteAxios
        .get(
          `/api/user/v1/shippings/status/${numero}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setPedido(response);
        })
        .catch((error) => {
          if (error.response.status === 400)
            setError(error.response.data.error_description);
          else if (error.response.status === 401)
            setError("La sesión ha caducado, debe iniciar sesión de nuevo.");
          else if (error.response.status === 404)
            setError("Pedido no encontrado, revise los datos introducidos.");
          else setError("Error desconocido, vuelva a intentarlo nuevamente.");
        });
    };
    handleSearch();
  }, [info]);

  return (
    <div className="contenedor-app">
      <SideBar setInfo={setInfo} />

      <div className="seccion-principal">
        <Header />
        <main>
          <div className="contenedor-tareas">
            <Result pedido={pedido} error={error} />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Search;
